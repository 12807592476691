<template>
  <div><br>
    <el-button v-if="orderInfo.orderStatus.name==='PLACE_ORDER'" size="mini" type="primary" @click="handleReceiptReceive" v-waves>开始生产</el-button>
    <span v-if="orderInfo.orderStatus.name==='PLACE_ORDER'" style="margin-left: 10px;color:red; font-size: 12px">* 无效标签不进行生产</span>
    <el-button v-if="orderInfo.orderStatus.name==='RECEIVE'" size="mini" type="primary" @click="handleReceiptShipped" v-waves>完成并发货</el-button>
    <span v-if="orderInfo.orderStatus.name==='RECEIVE'" style="margin-left: 10px;color:red; font-size: 12px">* 无效标签不进行发货</span>
    <el-divider content-position="left">订单基础信息</el-divider><br>
      <el-descriptions
      class="margin-top"
      title=""
      :column="4"
      :size="medium"
       direction="vertical"
      border
      >
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            订单批次
          </template>
          {{orderInfo.orderBatch}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            下单时间
          </template>
          {{orderInfo.orderTime}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            防伪码标签（套）
          </template>
          {{orderInfo.securityNumber}}
        </el-descriptions-item>
        <el-descriptions-item align="center">
          <template #label>
            <el-icon></el-icon>
            条形码标签（枚）
          </template>
          {{orderInfo.barcodeNumber}}
        </el-descriptions-item>
    </el-descriptions><br>
    <el-divider content-position="left">标签详情</el-divider>
    <el-button size="mini" type="primary" @click="exportLabelInfo" v-if="orderInfo.orderStatus.name!='CANCEL'" v-waves>导出全部标签详情</el-button>
    <el-button size="mini" type="primary" @click="exportApplyLabelInfo" v-if="orderInfo.orderStatus.name!='CANCEL'" v-waves>导出申请标签详情</el-button>
    <el-button size="mini" type="primary" @click="exportSecurityCodeInfo" v-if="orderInfo.orderStatus.name!='CANCEL'" v-waves>导出SN标签与防伪码生产资料</el-button>
    <span style="margin-left: 10px;color:red; font-size: 12px" v-if="orderInfo.orderStatus.name!='CANCEL'" >* 申请标签不包含追加标签以及无效标签</span>
    <br><br>
    <el-table :data="labelDetails" style="width: 100%" border id="labelTable">
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column width="80px" label="标签状态" align="center">
          <template #default="scope">
            <el-tag :type="showLabelStateFilter(scope.row)">{{scope.row.labelState?scope.row.labelState.type:''}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="model.materialCode" label="物料代码" width="120" align="center"/>
      <el-table-column prop="model.name" label="型号" width="100" align="center"/>
      <el-table-column prop="model.unit" label="单位" width="50" align="center"/>
      <el-table-column label="类型" align="center" width="80px">
          <template  #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.model.type && scope.row.model.type.title || ''}}</el-tag>
          </template>
        </el-table-column>
      <el-table-column prop="manufacturer.name" label="制造商" align="center"/>
      <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
      <el-table-column prop="model.page" label="联数" width="50" align="center"/>
      <el-table-column prop="labelCount" label="防伪码标签（套）" width="80" align="center"/>
      <el-table-column prop="barcodeNumber" label="条形码标签（枚）" width="80" align="center">
        <template #default="scope">
            <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="startLabel" label="起始标签" width="220" align="center"/>
      <el-table-column prop="endLabel" label="结束标签" width="220" align="center"/>
      <el-table-column prop="order.orderBatch" label="订单批次" width="150" align="center"/>
      <el-table-column prop="productionRemark" label="发货备注" width="150" align="center"/>
    </el-table>
    <div v-if="orderInfo.orderStatus.name==='SHIPPED'">
      <el-divider content-position="left">发货单</el-divider>
      <el-button size="mini" type="primary" @click="downExcel" v-waves>导出发货单</el-button><br><br>
      <el-table :data="labelDetails" style="width: 100%" border id="labelTable">
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="manufacturer.name" label="制造商" min-width="200" align="center"/>
        <el-table-column prop="manufacturer.code" label="制造商代码" min-width="200" align="center"/>
        <el-table-column prop="labelCount" label="防伪码标签（套）" min-width="250" align="center" />
        <el-table-column prop="barcodeNumber" label="条形码标签总数（枚）" min-width="250" align="center">
          <template #default="scope">
              <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order.orderBatch" label="订单批次" min-width="250" align="center" />
      </el-table>
    </div>
    <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="80%">
        <component :is="startupForm" v-model:show="startupFormDialogShow" v-model:workflowNode="workflowNode" @close="startupFormDialogShow=false;getOrderInfoById();getLabelInfoByOrderId()"></component>
    </el-dialog>
  </div>
</template>

<script>
import orderProductInfoApi from '@/apis/order/orderProductInfo'
import ledgerForm from './ledgerForm'
export default {
  props: {
    id: {
      type: String
    }
  },
  components: {
    ledgerForm
  },
  data() {
    return {
      orderId: this.id,
      startupFormDialogShow: false,
      startupForm: null,
      orderInfo: {
        orderBatch: undefined,
        securityNumber: undefined,
        barcodeNumber: undefined,
        orderTime: undefined,
        orderStatus: {
          name: undefined,
          type: undefined
        }
      },
      labelDetails: [],
      params:{
        businessKey: undefined,
        localVariables: {
            approved: undefined,
            note: undefined
        }
      },
      workflowNode: {
        businessObject: {
        }
      }
    }
  },
  created() {
    this.getOrderInfoById()
    this.getLabelInfoByOrderId()
  },
  mounted(){
  },
  methods:{
    showFilter (row) {
      return row.model.type && row.model.type.value === 'HOME_APPLIANCES' ? 'success' : 'warning'
    },
    showLabelStateFilter(row){
      if(row.labelState && row.labelState.name === 'IN_EFFECT'){
        return 'success'
      }else{
        return 'danger'
      }
    },
    getOrderInfoById(){
      if(this.orderId){
        orderProductInfoApi.getOrderInfoById(this.orderId).then(resp=>{
          if(resp.data){
            this.orderInfo = resp.data
          }
        })
      }
    },
    getLabelInfoByOrderId(){
      if(this.orderId){
        orderProductInfoApi.getLabelInfoByOrderId(this.orderId).then(resp =>{
          if(resp.data){
            this.labelDetails = resp.data.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeNumber = labelInfo.labelCount * labelInfo.model.page
              }else{
                labelInfo.barcodeNumber = 0
              }
              return labelInfo
            })
          }
        })
      }
    },
    handleReceiptReceive(){
      //修改状态
      orderProductInfoApi.getTasks({
        assignee: this.$store.state.user.account,
        processDefinitionKeys: [this.orderInfo.processDefinitionKey],
        businessKey: this.orderInfo.id
      }).then(resp=>{
        var task = resp.data[0]
        if (task) {
          this.task = task
          this.taskId = task.id
          this.params.localVariables.approved = true
          this.params.localVariables.note = '通过'
          var orderStatus = {
            orderId:  this.orderInfo.id,
            status: {
              type: '生产中',
              name: 'RECEIVE'
            }
          }
          orderProductInfoApi.audit({orderStatus: orderStatus,taskId:this.taskId,...this.params}).then(resp=>{
            if (resp.data) {
              this.$message.success("成功")
              this.getOrderInfoById()
              this.getLabelInfoByOrderId()
            }
          })
        }else{
          this.$message.warning("无权操作")
          this.task = null
          this.taskId = null
        }
      })
    },
    handleReceiptShipped(){
      this.workflowNode = {
        processInstanceId: this.orderInfo.processInstanceId,
        assignee: this.$store.state.user.account,
        processDefinitionKey: this.orderInfo.processDefinitionKey,
        businessKey: this.orderInfo.id,
        businessObject: this.orderInfo,
        isInfo: true
      }
      this.startupForm = 'ledgerForm'
      this.startupFormDialogShow = true
    },
    downExcel () {
      var name = this.orderInfo.orderBatch
      orderProductInfoApi.exportInfoByOrderId(this.orderInfo.id).then(resp => {
        if(resp.data){
          saveAs(resp.data, `${name}.xlsx`)
        }
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    exportLabelInfo () {
      orderProductInfoApi.exportLabelInfo(this.orderInfo.id).then(resp => {
        if(resp.data){
          saveAs(resp.data, `标签详情单.xlsx`)
        }
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    exportApplyLabelInfo(){
      orderProductInfoApi.exportApplyLabelInfo(this.orderInfo.id).then(resp => {
        if(resp.data){
          saveAs(resp.data, `申请标签详情单.xlsx`)
        }
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    exportSecurityCodeInfo() {
      orderProductInfoApi.exportSecurityCodeInfo(this.orderInfo.id).then(resp => {
        console.log(resp.data.status)
        if (resp.data.status === 1000) {
          this.$message.info("正在下载，请稍等一会。")
          window.open(resp.data.msg, '_blank');
        }else {
          this.$message.info(resp.data.msg)
        }
      }).catch(res => {
        this.$message.error("无法下载")
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>